<template>
  <ui-component-modal
    :modalTitle="$t('Components.Terms.EditTerms.Text.Main_Title')"
    modalSize="extra-large"
    :isSaving="isSaving"
    :isSavingSuccess="isSavingSuccess"
    :isSavingError="isSavingError"
    :hideFooterButtons="isSavingError || isSavingSuccess"
    :onClickCancel="onClickCancel"
    :onClickSave="saveTermText"
    :showModal="showModal"
    @closeModal="onClickCancel"
  >
    <template slot="modalTitle"></template>
    <template v-slot:content>
      <div class="has-margin-bottom-x2">
        <Message :message="$t('Components.Terms.EditTerms.Text.Message_Info')" />
      </div>

      <div class="divider">
        {{ $t('Components.Terms.EditTerms.Text.DividerHeader_Changes') }}
      </div>
      <div class="columns is-multiline">
        <div
          v-for="(language, index) in mBookingTerm.Languages"
          :key="'column' + index"
          class="column is-half"
        >
          <p>{{ language.Language }}</p>
          <div class="field">
            <div class="control">
              <textarea
                v-model="language.Changes"
                class="textarea"
                maxlength="6000"
              ></textarea>
            </div>
          </div>
        </div>
      </div>

      <div class="divider">
        {{ $t('Components.Terms.EditTerms.Text.DividerHeader_Terms') }}
      </div>
      <div class="columns is-multiline">
        <div
          v-for="(language, index) in mBookingTerm.Languages"
          :key="'column' + index"
          class="column is-half"
        >
          <p>{{ language.Language }}</p>
          <div class="field">
            <div class="control">
              <ui-text-editor
                :id="'editor' + index"
                v-model="language.Terms"
              ></ui-text-editor>
            </div>
          </div>
        </div>
      </div>
    </template>
  </ui-component-modal>
</template>

<script>
import Vue from 'vue'
import { mapState, mapMutations } from 'vuex'
import { EventBus } from '@/eventbus/event-bus'
import termsProvider from '@/providers/terms'

const Message = () =>
  import(/* webpackChunkName: "reservations-list" */ '@/components/UI/Message')

export default {
  components: {
    Message,
  },

  props: {
    showModal: {
      type: Boolean,
      default: false,
    },

    onClickCancel: {
      type: Function,
      required: true,
    },

    channelId: {
      type: Number,
      default: 0,
    },

    bookingTerm: {
      type: Object,
      default: null,
    },
  },

  data() {
    return {
      isSaving: false,
      isSavingSuccess: false,
      isSavingError: false,
      mBookingTerm: {},
    }
  },

  computed: {
    ...mapState('locationStore', ['locationState']),
  },

  created() {
    if (this.bookingTerm) {
      this.mBookingTerm = JSON.parse(JSON.stringify(this.bookingTerm))
    }
  },

  methods: {
    saveTermText() {
      let self = this

      if (!self.isSaving) {
        self.isSaving = true
        termsProvider.methods
          .updateBookingTerm(self.mBookingTerm)
          .then((response) => {
            if (response.status === 200) {
              self.isSavingSuccess = true
              let t = setTimeout(() => {
                self.$emit('termUpdated')
                self.onClickCancel()
                clearTimeout(t)
              }, 1500)
            }
          })
          .catch((error) => {
            //console.log(error.response)
            self.isSavingError = true
          })
          .finally(() => {
            self.isSaving = false
          })
      }
    },
  },
}
</script>
